<template>
  <b-card v-loading="loading">
    <el-form
      ref="form"
      :inline="true"
      label-width="100px"
      :label-position="'right'"
      :model="form"
    >
      <el-form-item
        label="协议名称："
        class="mr-2"
        prop="name"
        :rules="[{ required: true, message: '协议名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.name"
          maxlength="40"
          placeholder="请输入协议名称"
        />
      </el-form-item>
      <el-form-item
        label="第三方："
        prop="thirdPartyId"
        :rules="[{ required: true, message: '请选择产品', trigger: 'change' }]"
      >
        <el-select
          v-model="form.thirdPartyId"
          placeholder="请选择第三方"
          clearable
        >
          <el-option
            v-for="(item,index) in thirdPartyOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="来源：">
        <span>通用协议模板</span>
      </el-form-item>
    </el-form>
    <div class="flex-start match-height editor-wrap">
      <div class="doc-left">
        <div class="add-doc">
          <el-button
            class="btn-block text-center"
            size="small"
            type="primary"
            @click="addSection"
          >
            添加新模块
          </el-button>
        </div>
        <VuePerfectScrollbar
          v-loading="tagLoading"
          :style="{height: pageH - 350 +'px'}"
          :settings="settings"
        >
          <draggable
            v-model="form.infoModels"
            handle=".mover"
            tag="ul"
            :options="{forceFallback:true, fallbackClass:'draggingStyle'}"
            @end="onEnd"
          >
            <li
              v-for="(item,index) in form.infoModels"
              :key="index"
              :class="activeIndex == index ? 'doc-active' : ''"
              class="section-item"
              @click="sectionClick(item,index)"
            >
              <div
                v-show="!item.editor"
                class="flex-start mover"
                style="width: 100%"
              >
                <feather-icon
                  icon="CircleIcon"
                  class="mr10"
                />
                <el-tooltip
                  v-if="item.modelName.length >= 14"
                  class="item"
                  effect="light"
                  :content="item.modelName"
                  placement="top"
                >
                  <span
                    style="max-width: 70%"
                    class="overflow-ellipsis directoryName"
                  >{{ item.modelName }}</span>
                </el-tooltip>
                <span
                  v-else
                  style="max-width: 70%"
                  class="overflow-ellipsis directoryName"
                >{{ item.modelName }}</span>
              </div>
              <div
                v-show="!item.editor"
                class="flex-end section-operate"
              >
                <i
                  class="el-icon-edit mr10"
                  @click="editorShow(item)"
                />
                <i
                  class="el-icon-delete"
                  @click.stop="delSection(index)"
                />
              </div>
              <el-input
                v-show="item.editor"
                v-model="item.modelName"
                v-input-filter
                maxlength="20"
                @blur="updateDirectoryName(item,index)"
              >
                <template slot="append">
                  <i
                    class="el-icon-check"
                    @click="updateDirectoryName(item,index)"
                  />
                </template>
              </el-input>
            </li>
          </draggable>
        </VuePerfectScrollbar>
      </div>
      <div class="doc-right">
        <div
          v-for="(item,index) in form.infoModels"
          v-if="activeIndex == index"
          :key="index"
        >
          <EditorVue :content.sync="item" />
        </div>
      </div>
    </div>
    <div class="dialog-footer text-center mt20">
      <el-button
        size="small"
        @click="back"
      >
        取 消
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >
        保 存
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
  </b-card>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { error, success } from '@core/utils/utils'
import draggable from 'vuedraggable'
import EditorVue from '@core/components/editor-vue/EditorVue2'
import { GetThirdPartyOptionList } from '@/api/enterprise/assest'
import { GetDetailById, SaveAndUpdateAgreement, GetTemplateContent } from '@/api/thirdPartCompliance/thirdPartCompliance'

export default {
  components: {
    VuePerfectScrollbar, draggable, EditorVue,
  },
  data() {
    return {
      thirdPartyOptions: [],
      loading: false,
      form: {
        name: '',
        templateCode: this.$route.query.tId,
        id: Number(this.$route.query.id),
        infoModels: [],
      },
      tagLoading: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      activeIndex: 0,
      isInit: true,
      tId: this.$route.query.tId,
      pId: this.$route.query.pId,
      c: this.$route.query.c,
      htmlStr: '',
      editor: null,
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default',
    }
  },
  created() {
    this.getThirdPartyOptions()
    if (this.tId) {
      this.queryTemplateById()
    }
    if (this.$route.query.id) {
      this.form.id = Number(this.$route.query.id)
      this.getDetailById()
    }
  },
  mounted() {
    this.navActiveInit(1, '合作协议管理', '第三方合规管理')
  },
  destroyed() {
    this.navActiveInit(2, '合作协议管理', '第三方合规管理', '/cooperation')
  },
  methods: {
    preview() {
      let htmlStr = ''
      this.form.infoModels.forEach(d => {
        if (d.modelValue) {
          htmlStr += d.modelValue
        }
      })
      if (htmlStr) {
        localStorage.setItem('htmlStr', htmlStr)
        const routeUrl = this.$router.resolve({
          path: '/cooperation-read',
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
    getThirdPartyOptions() {
      GetThirdPartyOptionList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyOptions = resData.data
        }
      })
    },
    getDetailById() {
      GetDetailById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.infoModels.forEach(item => {
            this.$set(item, 'editor', false)
          })
          if (this.c) {
            this.form.name = `${this.form.name}-副本`
          }
        }
      })
    },
    queryTemplateById() {
      GetTemplateContent(this.tId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form.infoModels = resData.data ? resData.data : []
          this.form.infoModels.forEach(item => {
            this.$set(item, 'editor', false)
          })
        }
      })
    },
    onEnd(event) {
      this.activeIndex = event.newIndex
    },
    back() {
      this.$router.push({
        path: '/cooperation',
      })
    },
    addSection() {
      this.form.infoModels.push({
        modelValue: '',
        modelName: '',
        editor: true,
      })
      this.activeIndex = this.form.infoModels.length - 1
      this.htmlStr = this.form.infoModels[this.activeIndex].modelValue
    },
    delSection(index) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.form.infoModels.splice(index, 1)
        this.activeIndex = 0
        this.htmlStr = this.form.infoModels[0].modelValue
      })
    },
    updateDirectoryName(item, index) {
      if (item.modelName.length === 0) {
        this.form.infoModels.splice(index, 1)
      } else {
        item.editor = false
      }
    },
    editorShow(item) {
      this.form.infoModels.forEach(s => {
        s.editor = false
      })
      item.editor = true
    },
    sectionClick(item, index) {
      // this.htmlStr = item.content
      this.activeIndex = index
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    change() {
      if (!this.isInit) {
        this.form.infoModels[this.activeIndex].modelValue = this.htmlStr
      }
      this.isInit = false
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.infoModels.length > 0) {
            this.loading = true
            if (!this.form.id || this.c) {
              delete this.form.id
            }
            const newInfoModels = []
            this.form.infoModels.forEach((m, index) => {
              if (m.modelName.length > 0) {
                newInfoModels.push(m)
              }
            })
            this.form.infoModels = newInfoModels
            SaveAndUpdateAgreement(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.back()
              } else {
                error(resData.msg)
              }
            })
          } else {
            error('模块不能为空')
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss">
.directoryName {
  cursor: move;
}
.doc-dropdown .btn-secondary,
.doc-dropdown .btn-secondary:hover {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.feather-more-vertical {
  color: #6e6b7b !important;
}
.editor-wrap {
  height: 100%;
  border: 1px solid #ebe9f1;
  align-items: baseline;
  border-radius: 4px;
  .doc-left {
    width: 260px;
    border-right: 1px solid #ebe9f1;
    height: 100%;
    .add-doc {
      padding: 10px;
    }
    .section-item {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-w_wendang {
        margin-right: 10px;
      }
      i {
        font-size: 20px;
      }
      .el-input-group__append,
      [dir] .el-input-group__prepend {
        padding: 0 5px !important;
      }
    }
    .section-operate {
      display: none;
      position: absolute;
      right: 10px;
    }
    .section-item:hover {
      .section-operate {
        display: block;
      }
    }
    .section-item.doc-active {
      border-left: 2px solid #0d40b2;
      color: #0d40b2;
      position: relative;
    }
    .tag-title {
      padding: 10px;
      border-top: 1px solid #ebe9f1;
      border-bottom: 1px solid #ebe9f1;
      align-items: center;
      /*color: #b9b9c3;*/
    }
  }
  .doc-right {
    width: calc(100% - 260px);
    height: 100%;
    .doc-search-wrap {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #ebe9f1;
      height: 40px;
      .doc-search-left {
        width: 80%;
        .el-input__inner {
          border: none !important;
        }
      }
      .el-dropdown {
        display: flex;
        align-items: center;
      }
    }

    .doc-li {
      /*transition: all .2s;*/
      cursor: pointer;
      padding: 20px 10px;
      border-bottom: 1px solid #ebe9f1;
      color: #6e6b7b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .feather-more-vertical {
        margin-right: 10px;
        position: relative;
        top: 2px;
        opacity: 0;
      }
      .documentName {
        /*margin-left: 10px;*/
        font-size: 12px;
        color: #6e6b7b;
      }
    }
    .doc-li:hover {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 #ebe9f1;
      .feather-more-vertical {
        opacity: 1;
      }
    }
  }
}
</style>
<style>
.w-e-text-container table td,
.w-e-text-container table th {
  border: 1px solid var(--w-e-textarea-border-color);
  padding: 3px 5px;
}
</style>
