<template>
  <div>
    <div>
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :default-config="toolbarConfig"
        :mode="mode"
      />
      <Editor
        v-model="content.modelValue"
        style="overflow-y: hidden;"
        :style="{height: pageH - 450 +'px'}"
        :default-config="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
        @onChange="change"
      />
    </div>
  </div>

</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar },
  props: {
    content: {
      type: Object,
      default() {
        return {modelValue: ''}
      }
    }
  },
  data() {
    const that = this
    return {
      contentImages: [],
      editor: null,
      htmlStr: '',
      toolbarConfig: {
        toolbarKeys: [
          'header1',
          // 'bold',
          // 'underline',
          // 'italic',
          // 'through',
          // 'code',
          // 'sub',
          // 'sup',
          // 'clearStyle',
          'color',
          'bgColor',
          'fontSize',
          // 'fontFamily',
          'indent',
          // 'delIndent',
          // 'justifyLeft',
          // 'justifyRight',
          // 'justifyCenter',
          // 'justifyJustify',
          // 'lineHeight',
          "insertImage",
          "deleteImage",
          // "editImage",
          "viewImageLink",
          // "imageWidth30",
          // "imageWidth50",
          // "imageWidth100",
          // 'divider',
          // 'emotion',
          'insertLink',
          // 'editLink',
          // 'unLink',
          // 'viewLink',
          // 'codeBlock',
          // 'blockquote',
          // 'headerSelect',

          // 'header2',
          // 'header3',
          // 'header4',
          // 'header5',
          'todo',
          'redo',
          'undo',
          // "fullScreen",
          'bulletedList',
          'numberedList',
          'insertTable',
          'deleteTable',
          'insertTableRow',
          'deleteTableRow',
          'insertTableCol',
          'deleteTableCol',
          'tableHeader',
          "tableFullWidth",
          // "insertVideo",
          // "uploadVideo",
          "uploadImage",
          // "codeSelectLang"
        ],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: `${window.g.baseUrl}/sys/common/img/upload`,
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 2M
            // maxFileSize: 1 * 1024 * 1024 * 1024, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              token: 'x-Token',
              otherKey: localStorage.getItem('accessToken'),
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
              Accept: 'text/x-json',
              'x-Token': localStorage.getItem('accessToken'),
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 5 * 1000, // 5 秒
            // 单个文件上传成功之后
            onSuccess(file, res) {

            },
            onError(file) {
              if (file.size > 2 * 1024 * 1024) {
                error('单张图片大小不能超过2M')
              }
              console.log(file.size)
            },
            customInsert(res, insertFn) {
              insertFn(res.data.assessPath, '', '')
              if(that.content.imagelist) {
                that.content.imagelist.push(res.data.assessPath)
              }

              console.log(that.content)
            },
          },
        },
      },
      mode: 'default', // or 'simple'
    }
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器

    setTimeout(() => {
      // this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },
  beforeDestroy() {
    // const { editor } = this
    // if (editor == null) return
    // editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    change(){
      // this.$emit('update:content', this.htmlStr)
    }
  },
}
</script>
<style>
    .w-e-text-container  table td,  .w-e-text-container table th {
        border: 1px solid var(--w-e-textarea-border-color);
        padding: 3px 5px;
    }
</style>
<style src="../../../../node_modules/@wangeditor/editor/dist/css/style.css"></style>
